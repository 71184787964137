.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

*[role="button"] {
  cursor: pointer;
}

/* Define the scrollbar track */
::-webkit-scrollbar {
  width: 5px; /* Set the width for WebKit browsers (Chrome, Safari, etc.) */
  height: 7px;
}

/* Define the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #aaa; /* Set the color of the thumb */
  border-radius: 5px; /* Optional: Set border radius for a rounded look */
}

/* Define the scrollbar track for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #aaa transparent; /* Set the color of the thumb and track */
}

/* Optional: Define the scrollbar thumb for Firefox */
*::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

.overflow-hidden {
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
