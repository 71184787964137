:root {
  --COLOR_BLACK: #141414;
  --COLOR_WHITE: #ffffff;
  --COLOR_PRIMARY: #1675b3;
  --COLOR_SECONDARY: #4bd553;
  --COLOR_INFO: #2a93fa;
  --COLOR_ERROR: #ff3333;
  --COLOR_WARNING: #ffc152;
  --COLOR_GRAY: #8c7388;
  --COLOR_DIVIDER: #e8e7ed;
  --COLOR_LIGHT: #f6f5ff;
  --COLOR_LOW_CONTRAST_NEUTRAL: #b8b6c7;
  --COLOR_DARK_100: #454b75;
  --SANS_FONT_FAMILY: "Inter", "Helvetica", "Arial", sans-serif;
}
