.custom-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-tooltip::after {
  content: attr(data-tooltip);
  position: absolute;
  top: 200%; /* Adjust to position above the text */
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 1;
  visibility: hidden;
  font-size: 12px;
  z-index: 3; /* The z index of dhx_cal_header is 2 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease-in-out;
  background: var(--COLOR_WHITE);
}

.custom-tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}

.custom-tooltip img {
  width: 14px;
  height: 14px;
  margin-left: 4px;
  vertical-align: middle;
  opacity: 0.53;
}
