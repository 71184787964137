.dhx_scale_holder_now {
  background-color: #f9f87119;
}

.dhx_scale_holder.weekend {
  background-color: var(--COLOR_LIGHT);
}

.dhx_calendar_title {
  letter-spacing: 0.2px;
  font-family: var(--SANS_FONT_FAMILY);
  font-weight: 700;
}

.dhx_calendar_caption {
  letter-spacing: 0.2px;
  font-family: var(--SANS_FONT_FAMILY);
  font-weight: 300;
  font-size: 11px;
}
.dhx_cal_event_clear {
  color: var(--COLOR_LIGHT);
}
.dhx_cal_event_clear b {
  padding-left: 8px;
}

/* Ensure the scheduler header doesn't clip tooltips */
.dhx_cal_header {
  overflow: visible !important;
}

@media screen and (max-width: 960px) {
  .dhx_scale_bar {
    font-size: 10px;
  }
  .dhx_minical_popup {
    left: 64px !important;
  }
}
